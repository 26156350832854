import React, { useEffect, useState } from "react";
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import axios from "axios";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import TranslationService from "../../services/translation.service";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const computeTitle = (title) => {
  console.log("COMPUTE TITLE")
  let language = localStorage.getItem("pageLanguage");
  if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
  }
  return title
}

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{computeTitle(column.render('Header'))}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}


const LogTable = () => {
  // Variables
  const [songs, setSongs] = useState([]);
  const [gotSongs, setGotSongs] = useState(false);
  const [apiURL, setApiURL] = useState("http://127.0.0.1:8080/")
  const [value, setValue] = useState("2021-05-04");

  // Effects
  useEffect(() => {
    // let config = {
    //   method: 'get',
    //   maxBodyLength: Infinity,
    //   url: 'http://192.168.163.30:3000/loglist?date=2021-05-04',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     setSongs(response.data);
    //     setGotSongs(true);
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    if (!gotSongs) {
      var api = `{"api":"AnaLogList","name": "${name}", "date":"2021-05-04"}`;
      waitForSocketConnection(socket, () => { sendMessage(api) });
      setGotSongs(true);
    }
  
    socket.onmessage = (message) => {
      console.log("Message ", message);
      if ((message.data) && (message.data.toString().includes("Activity"))&& (message.data.toString().includes("oid"))) {
          var str = message.data.substring(0, message.data.length - 1);
          // console.log(str);
          try {
              var obj = JSON.parse(message.data);
              setGotSongs(true);
              setSongs(transformData(obj));
              return true;
          } catch (e) {
              console.log(e);
              return false;
          }
      }
  };
  }, [songs]);

  const transformData = (obj) => {
    let finalObject = []
    for (let i = 0; i < obj.length; i++) {
      obj[i]["UserID"] = computeTitle(obj[i]["UserID"])
      obj[i]["Activity"] = computeTitle(obj[i]["Activity"])
      obj[i]["CommunicationMode"] = computeTitle(obj[i]["CommunicationMode"])
      obj[i]["Description"] = computeTitle(obj[i]["Description"])
      finalObject.push(obj[i])
    }
    return obj
  }

  // Memos
  const columns = React.useMemo(
    () => [
      {
        Header: 'Log ID',
        accessor: '_id.$oid',
      },
      {
        Header: 'User ID',
        accessor: 'UserID',
      },
      {
        Header: 'Activity',
        accessor: 'Activity',
      },
      {
        Header: 'Date',
        accessor: 'Date',
      },
      {
        Header: 'Time',
        accessor: 'Time',
      },
      {
        Header: 'VideoFile',
        accessor: 'VideoFile',
      },
      {
        Header: 'CommunicationMode',
        accessor: 'CommunicationMode',
      },
      {
        Header: 'Description',
        accessor: 'Description',
      }
    ],
    []
  )

  // Functions
  const onChangeDate = (e) => {
    let day = String(e.getDate());
    let month = String(e.getMonth() + 1);
    let year = String(e.getFullYear());

    if (day.length == 1) {
      day = "0" + day
    }

    if (month.length == 1) {
      month = "0" + month
    }

    let fullDate = year +  '-' + month + '-' + day
    var api = `{"api":"AnaLogList","name": "${name}", "date":"${fullDate}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    setValue(fullDate);
  }

  // Build Page
  return (
    <Styles>
      <TitleBar title={computeTitle("Analytics: Interactions")} parentNode={"/analytics"} />
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          label="Select Date"
          value={value}
          onChange={(newValue) => onChangeDate(newValue)}
        />
      </MuiPickersUtilsProvider> */}
      <Table columns={columns} data={songs} />
    </Styles>
  )
}

export default LogTable;