import React, { useState } from 'react';
import TitleBar from '../Common/TitleBar';
import { sendMessage, socket, waitForSocketConnection } from '../../socket';

function RobotControl() {
    const [direction, setDirection] = useState('');

    const handleButtonClick = (newDirection) => {
        setDirection(newDirection);
        var api = `{"api":"webBtnPressed", "button":"${newDirection}", "name": "${localStorage.getItem("remoteId")}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    };

    return (
        <div>
            <TitleBar title={"Robot Control"} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button style={{ margin: '0 10px', padding: '10px 20px', border: 'none', borderRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }} onClick={() => handleButtonClick('L')}>L</button>
                <button style={{ margin: '0 10px', padding: '10px 20px', border: 'none', borderRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }} onClick={() => handleButtonClick('C')}>C</button>
                <button style={{ margin: '0 10px', padding: '10px 20px', border: 'none', borderRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }} onClick={() => handleButtonClick('R')}>R</button>
            </div>
            <p>Recently pressed: {direction}</p>
        </div>
    );
}

export default RobotControl;